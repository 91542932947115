import * as React from 'react';
import cx from 'classnames';
import s from './ProductList.scss';
import {GridType, IProduct} from '../../../types/galleryTypes';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export interface ProductListGridProps extends IGalleryGlobalProps {
  focusAt: number;
  gridType: GridType;
  isMobileOverride: boolean;
  forceSingleColumn: boolean;
  products: IProduct[];
  renderKey: string;
  ProductItem: Function;
  editableGridTemplateRepeatOption?: boolean;
  shouldShowLoadMoreButton?: boolean;
}

export enum ProductListGridDataHook {
  List = 'product-list-wrapper',
  Item = 'product-list-grid-item',
}

export class ProductListGrid extends React.Component<ProductListGridProps> {
  private readonly itemRefs = [];
  private a11yAnnouncer: Announcer;

  public static defaultProps = {
    focusAt: -1,
    gridType: GridType.MANUAL,
    isMobileOverride: false,
    forceSingleColumn: false,
    products: [],
    renderKey: 'string',
    shouldShowLoadMoreButton: true,
  };

  public componentDidMount() {
    this.a11yAnnouncer = new Announcer('gallery-list-announcer');
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  public componentDidUpdate(prevProps: ProductListGridProps) {
    if (this.props.focusAt !== prevProps.focusAt) {
      this.focusItem(this.props.focusAt);
    }
  }

  private focusItem(i) {
    this.itemRefs[i] && this.itemRefs[i].focus && this.itemRefs[i].focus();
  }

  public render() {
    const {
      products,
      isMobileOverride,
      forceSingleColumn,
      gridType,
      renderKey,
      ProductItem,
      editableGridTemplateRepeatOption,
    } = this.props;
    const classNames = cx(s.grid, {
      [s.editableGridTemplateRepeatOption]: editableGridTemplateRepeatOption,
      [s.autoGrid]: gridType === GridType.AUTO,
      [s.manualGrid]: gridType !== GridType.AUTO,
      [s.mobile]: isMobileOverride,
      [s.forceSingleColumn]: forceSingleColumn,
      [s.loadMoreButtonIsShowing]: this.props.shouldShowLoadMoreButton,
    });

    return (
      <ul className={classNames} data-hook={ProductListGridDataHook.List} data-grid-type={gridType}>
        {products.map((product, index) => {
          const id = product ? product.id : `product-${index}`;
          return (
            <li key={`${id}-${renderKey}`} data-hook={ProductListGridDataHook.Item}>
              {product && (
                <ProductItem
                  index={index}
                  product={product}
                  innerRef={(ref) => (this.itemRefs[index] = ref)}
                  a11yAnnouncer={this.a11yAnnouncer}
                />
              )}
            </li>
          );
        })}
      </ul>
    );
  }
}
