/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/button-has-type */
import React from 'react';
import s from './ExpandableOption.scss';

import {FilterType} from '../../../types/galleryTypes';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';
import {MinusSmall, PlusSmall} from '@wix/wix-ui-icons-common/on-stage';

export enum DataHook {
  ExpandIcon = 'filter-expand-icon',
  FullTitle = 'filter-full-title',
  OptionName = 'filter-option-name',
  Title = 'filter-title',
  TitleWrapper = 'filter-title-wrapper',
}

export interface IExpandableOptionProps extends IGalleryGlobalProps {
  children: React.ReactChild;
  expanded: boolean;
  filterType?: FilterType;
  title: string;
}

export interface IExpandableOptionState {
  expanded: boolean;
  optionName?: string;
}

class ExpandableOptionComp extends React.Component<IExpandableOptionProps, IExpandableOptionState> {
  constructor(props) {
    super(props);
    this.state = {
      expanded: props.expanded,
      optionName: '',
    };
  }

  public render() {
    const {expanded, optionName} = this.state;
    const {isCategoryPage} = this.props.globals;
    const {filterType} = this.props;
    const isColorPicker = filterType === FilterType.COLOR_OPTION;
    return (
      <>
        <button
          onClick={() => this.toggleExpand()}
          className={classNames(s.header, {[s.categoryPage]: isCategoryPage})}
          data-hook={DataHook.TitleWrapper}
          aria-expanded={expanded}>
          <span
            className={classNames(s.titleContainer, {[s.categoryPage]: isCategoryPage})}
            data-hook={DataHook.FullTitle}>
            <span data-hook={DataHook.Title}>{this.props.title}</span>
            {optionName && <span data-hook={DataHook.OptionName}>: {optionName}</span>}
          </span>
          <span data-hook={DataHook.ExpandIcon} className={s.expandIcon}>
            {expanded ? <MinusSmall /> : <PlusSmall />}
          </span>
        </button>
        <AnimateHeight easing={'cubic-bezier(.39,.11,.37,.99)'} duration={200} height={expanded ? 'auto' : 0}>
          <div
            className={classNames(s.content, {
              [s.expanded]: expanded,
              [s.categoryPage]: isCategoryPage,
              [s.colorPicker]: isColorPicker,
            })}>
            {this.renderChild()}
          </div>
        </AnimateHeight>
      </>
    );
  }

  private toggleExpand() {
    const nextState = {expanded: !this.state.expanded};
    /* istanbul ignore next: hard to test it */
    this.setState(nextState, () => {
      this.props.globals.updateLayout && this.props.globals.updateLayout();
    });
  }

  private readonly renderChild = () => {
    if (this.props.filterType !== FilterType.COLOR_OPTION) {
      return this.props.children;
    }

    return React.cloneElement(this.props.children as React.ReactElement, {
      showOptionName: (optionName) => !this.props.globals.shouldShowMobile && this.setState({optionName}),
    });
  };
}

export const ExpandableOption = withGlobals(ExpandableOptionComp);
