import React, {useMemo} from 'react';
import s from './ProductsCounter.scss';
import {IGalleryGlobalProps} from '../../../gallery/galleryGlobalStrategy';
import {withGlobals} from '../../../globalPropsContext';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import classNames from 'classnames';

export enum ProductsCounterDataHook {
  Counter = 'ProductsCounterDataHook.Counter',
  CounterInline = 'ProductsCounterDataHook.CounterInline',
}

export interface IProductsCounterProps extends IGalleryGlobalProps {
  mobileInlineMode?: boolean;
}

export const ProductsCounter: React.FunctionComponent<IProductsCounterProps> = withGlobals(
  withTranslations()(({globals, t, mobileInlineMode}: IProductsCounterProps & IProvidedTranslationProps) => {
    const {totalProducts, shouldShowMobile} = globals;

    const numberToShow = useMemo(() => {
      switch (totalProducts) {
        case 1:
          return t('productsCounterSingular');
        case 0:
          return t('productsCounterZero');
        default:
          return t('productsCounterPlural', {numProducts: totalProducts});
      }
    }, [t, totalProducts]);

    return mobileInlineMode ? (
      <span
        className={classNames(s.inlineMobileCounter, {[s.mobile]: shouldShowMobile})}
        data-hook={ProductsCounterDataHook.CounterInline}>
        ({numberToShow})
      </span>
    ) : (
      <div
        className={classNames(s.counter, {[s.mobile]: shouldShowMobile})}
        data-hook={ProductsCounterDataHook.Counter}>
        {numberToShow}
      </div>
    );
  })
);
